const debug = false;

const NAV = {
	small: document.querySelector('.nav--small'),
	menuBtn: document.querySelector('button.menu'),
	menuBtnText: document.querySelector('.menu__text'),
	menuBtnClose: document.querySelector('.menu__close'),
	menuItems: document.querySelectorAll('.menu__item'),
	menuLangs: document.querySelectorAll('.nav__menu .langswitcher__item'),
	menu: document.querySelector('.nav__menu'),
	large: document.querySelector('.nav--large'),
};

function simpleMenu() {
	NAV.menuBtn.addEventListener('click', function () {
		NAV.small.classList.toggle('open');
		if (debug) console.log('menu open');
	});

	function closeMenuOnclick() {
		if (NAV.small.classList.contains('open')) {
			NAV.small.classList.toggle('open');
		}
		if (debug) console.log('menu closed');
	}

	for (let menuItem of Object.values(NAV.menuItems)) {
		menuItem.addEventListener('click', closeMenuOnclick);
	}

	for (let menuLang of Object.values(NAV.menuLangs)) {
		menuLang.addEventListener('click', closeMenuOnclick);
	}
}

function updateHeader(namespace) {
	let navItems = NAV.large.querySelectorAll('.nav__item');

	for (let item of Object.values(navItems)) {
		/* reset all items state */
		if (item.matches('.active')) {
			item.classList.remove('active');
		}

		/* find current item */
		let name = item.dataset.namespace;
		if (name && name === namespace) {
			item.classList.toggle('active');
		}
	}
}

function updateMenu(namespace) {
	let menuItems = NAV.small.querySelectorAll('.menu__item');

	for (let item of Object.values(menuItems)) {
		/* reset all items state */
		if (item.matches('.active')) {
			item.classList.remove('active');
		}

		/* find current item */
		let name = item.dataset.namespace;
		if (name && name === namespace) {
			item.classList.toggle('active');
		}
	}
}

export { simpleMenu, updateHeader, updateMenu };
