import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

function anchors(container = document) {
	gsap.registerPlugin(ScrollToPlugin);

	container.querySelectorAll('.list__link').forEach((btn, index) => {
		btn.addEventListener('click', (e) => {
			// prevent link
			e.preventDefault();
			// scroll to item
			gsap.to('.repertory', {
				duration: 0,
				scrollTo: {
					y: `#group${btn.id}`,
					offsetY: 0,
					autoKill: true,
				},
			});
		});
	});
}

export { anchors };
