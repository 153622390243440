/* Search component script */
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

/* Hide search button before page transition when clicking on a link */
function hideSearchBtnOnClick(container = document) {
	if (document.querySelector('.search')) {
		const mediaQuery = window.matchMedia('(min-width: 768px)');
		let navItems = document.querySelectorAll('.nav__item');
		let menuItems = document.querySelectorAll('.menu__item');
		let mainMenuItems = container.querySelectorAll('.main-menu__item');

		function handleTabletChange(e) {
			if (e.matches) {
				navItems.forEach((item) => {
					item.onclick = () => {
						gsap.to('.search__button', {
							xPercent: -125,
						});
					};
				});

				mainMenuItems.forEach((item) => {
					item.onclick = () => {
						gsap.to('.search__button', {
							xPercent: -125,
						});
					};
				});
			} else {
				menuItems.forEach((item) => {
					item.onclick = () => {
						gsap.to('.search__button', {
							xPercent: 125,
						});
					};
				});

				mainMenuItems.forEach((item) => {
					item.onclick = () => {
						gsap.to('.search__button', {
							xPercent: 125,
						});
					};
				});
			}
		}

		// Register event listener
		mediaQuery.addListener(handleTabletChange);

		// Initial check
		handleTabletChange(mediaQuery);
	}
}

/* Slide in search button with Scrolltrigger */
function searchSlideInOnScroll() {
	/* /!\ keep me here /!\ */
	gsap.registerPlugin(ScrollTrigger);

	ScrollTrigger.defaults({
		scroller: '.js-search-scroller',
	});

	/* /!\ keep me here /!\ */
	/* Set the button position in searchAnimation() */
	/* Must be initialized before function */
	if (document.querySelector('.search')) {
		gsap.to('.search .search__button', {
			scrollTrigger: {
				scroller: '.js-search-scroller',
				toggleActions: 'play none none reverse',
				// start: `${start} top`,
				start: `60% top`,
				end: 'end end',
			},
			xPercent: 0,
		});
	}
}

/* Open/close search bar animations */
function searchToggleAnimation() {
	if (document.querySelector('.search')) {
		let wrapper = document.querySelector('.search__wrapper');
		let button = document.querySelector('.search__button');
		let tl = gsap.timeline({ paused: true });
		let mm = gsap.matchMedia();
		let breakPoint = 768;
		let power = 'power4.out';
		let speed = 0.5;

		mm.add(
			{
				isDesktop: `(min-width: ${breakPoint}px)`,
				isMobile: `(max-width: ${breakPoint - 1}px)`,
				reduceMotion: '(prefers-reduced-motion: reduce)',
			},
			(context) => {
				let { isDesktop, isMobile, reduceMotion } = context.conditions;

				gsap.set('.search__button', {
					xPercent: isDesktop ? -125 : 125,
				});

				gsap.set('.search__engine', {
					autoAlpha: 0,
				});

				gsap.set('.search__bar', {
					width: isDesktop ? '10%' : '100%',
					autoAlpha: 0,
					xPercent: isDesktop ? -10 : 0,
				});

				gsap.set('.search__backdrop', {
					autoAlpha: 0,
					xPercent: -100,
				});

				tl.to('.search__engine', {
					autoAlpha: 1,
					duration: speed,
					ease: power,
				});

				tl.to(
					'.search__bar',
					{
						xPercent: 0,
						autoAlpha: 1,
						width: '100%',
						duration: speed,
						ease: power,
					},
					`=-0.1`
				);

				tl.set(
					'.search__backdrop',
					{
						xPercent: 0,
					},
					`=-${speed}`
				);

				tl.to(
					'.search__backdrop',
					{
						autoAlpha: 1,
						duration: speed,
						ease: 'none',
					},
					`=-${speed} + 0.1`
				);
			}
		);

		/*  open/close search bar */
		function toggleSearchBar() {
			if (wrapper.classList.contains('open')) {
				// empty search first
				wrapper.querySelector('.search__input').value = '';
				let listItems = wrapper.querySelectorAll('.search__item');
				listItems.forEach((listItem) => (listItem.style.display = 'none'));
				// animation
				tl.timeScale(3).reverse();
				wrapper.classList.remove('open');
			} else {
				tl.timeScale(1).play();
				wrapper.classList.add('open');
			}
		}

		button.onclick = () => {
			toggleSearchBar();
		};

		/* Make search visible only at the end */
		/* Set to avoid flash on load */
		gsap.set('.search', {
			visibility: 'visible',
		});
	}
}

/* Search for matches in the list */
function searchActions(box, event) {
	let listItems = box.querySelectorAll('.search__item');
	let terms = event.target.value.toLowerCase();

	if (terms.length > 0) {
		listItems.forEach(function (listItem) {
			let itemText = listItem.textContent.toLowerCase();
			let itemData = listItem.dataset.course;

			if (itemText.includes(terms)) {
				// find text
				listItem.style.display = 'block';
			} else if (itemData.includes(terms)) {
				// find course
				listItem.style.display = 'block';
			} else {
				// else, hide
				listItem.style.display = 'none';
			}
		});
	} else {
		// hide all if there is no search terms
		listItems.forEach(function (listItem) {
			listItem.style.display = 'none';
		});
	}
}

/* Init search (could be removed) */
function search() {
	let wrapper = document.querySelector('.search__wrapper');
	if (wrapper) {
		let input = wrapper.querySelector('.search__input');

		input.addEventListener('input', function (event) {
			searchActions(wrapper, event);
		});
	}
}

export { hideSearchBtnOnClick, searchSlideInOnScroll, searchToggleAnimation, search };
