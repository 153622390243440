import barba from '@barba/core';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { viewport } from './components/viewport.js';
import { simpleMenu, updateHeader, updateMenu } from './components/header.js';
import {
	hideSearchBtnOnClick,
	searchSlideInOnScroll,
	searchToggleAnimation,
	search,
} from './components/search.js';
import { mainMenu } from './components/main-menu.js';
import { accordion } from './components/accordion.js';
import { slider, plyr } from './pages/student.js';
import { viewer } from './pages/yearbook.js';
import { anchors } from './pages/repertory.js';
gsap.registerPlugin(ScrollTrigger);

/* Do something before the transition starts */
barba.hooks.before(() => {
	// update header
	if (document.querySelector('.header')) {
		updateHeader(document);
	}

	// update menu
	if (document.querySelector('.header')) {
		updateMenu(document);
	}
});

/* Kill ScrollTriggers */
barba.hooks.afterLeave(() => {
	let count = 0;
	let triggers = ScrollTrigger.getAll();
	triggers.forEach(function (trigger) {
		console.log(trigger);
		count += 1;
		trigger.kill();
	});
});

/* Do something before next page enter */
barba.hooks.beforeEnter((data) => {
	/* Initialize the main-menu */
	if (data.next.container.querySelector('.main-menu')) {
		mainMenu(data.next.container);
	}
});

/* Show/hide search component based on pages */
barba.hooks.afterEnter((data) => {
	function showSearchComponent() {
		if (document.querySelector('.search.is-hidden')) {
			document.querySelector('.search').classList.remove('is-hidden');
		}
	}

	function hideSearchComponent() {
		if (document.querySelector('.search:not(.is-hidden)')) {
			document.querySelector('.search').classList.add('is-hidden');
		}
	}

	if (data.next.namespace === 'home') {
		showSearchComponent();
	}

	if (data.next.namespace === 'alumni') {
		showSearchComponent();
	}

	if (data.next.namespace === 'yearbook-item') {
		showSearchComponent();
	}

	if (data.next.namespace === 'repertory') {
		hideSearchComponent();
	}

	if (data.next.namespace === 'information') {
		hideSearchComponent();
	}

	hideSearchBtnOnClick(data.next.container);
});

// do something after the transition finishes
barba.hooks.after((data) => {
	/* Reload search components after transition */
	if (document.querySelector('.search')) {
		searchToggleAnimation();
		searchSlideInOnScroll();
	}
});

function leaveAnimation(e) {
	return new Promise(async (resolve) => {
		await gsap
			.to(e, {
				yPercent: -100,
				duration: 1.1,
				ease: 'none',
			})
			.then();
		resolve();
	});
}

function enterAnimation(e) {
	return new Promise((resolve) => {
		gsap.from(e, {
			yPercent: 100,
			duration: 1.1,
			ease: 'none',
		}).then(resolve());
	});
}

barba.init({
	// debug: true,
	timeout: 5000,
	preventRunning: true, // prevent page “force reload” when the user clicks on an eligible link during a transition is running
	transitions: [
		{
			sync: true,
			name: 'slide-up',
			leave: ({ current }) => leaveAnimation(current.container),
			enter: ({ next }) => enterAnimation(next.container),
		},
	],
	views: [
		{
			namespace: 'alumni',
			beforeEnter(data) {
				// accordion
				if (data.next.container.querySelector('.accordion.list--alumni')) {
					accordion(data.next.container, '.accordion.list--alumni');
				}
				// alumni slider
				if (data.next.container.querySelector('.swiper--alumni')) {
					slider(data.next.container, '.swiper--alumni');
				}
				// alumni plyr
				if (data.next.container.querySelector('#player')) {
					plyr();
				}
			},
		},
		{
			namespace: 'yearbook-item',
			beforeEnter(data) {
				// accordion
				if (data.next.container.querySelector('.accordion.list--yearbook')) {
					accordion(data.next.container, '.accordion.list--yearbook');
				}
				// PDF slider
				if (data.next.container.querySelector('.swiper--pdf')) {
					viewer(data.next.container, '.swiper--pdf');
				}
			},
		},
		{
			namespace: 'repertory',
			beforeEnter(data) {
				if (data.next.container.querySelector('.list--repertory')) {
					anchors(data.next.container);
				}
			},
		},
	],
});

window.addEventListener('load', function () {
	/* Sizing the viewport */
	if (document.querySelector('html')) {
		viewport();
	}

	/* Load search dependencies once */
	if (document.querySelector('.search')) {
		searchToggleAnimation();
		searchSlideInOnScroll();
		hideSearchBtnOnClick();
		search();
	}

	/* Load main-menu once */
	// if (document.querySelector('.main-menu')) {
	// 	mainMenu();
	// }

	/* Load the menu once */
	if (document.querySelector('.nav--small')) {
		simpleMenu();
	}
});
