// import Swiper from 'swiper/bundle';
const debug = false;

function viewer(container = document, selector = '.swiper') {
	let viewer = container.querySelector(selector);

	if (viewer) {
		const v = new Swiper(viewer, {
			loop: true,
			// speed: 0,
			speed: 150,
			allowTouchMove: false,

			lazy: true,
			lazyPreloadPrevNext: 2,

			effect: 'fade',
			fadeEffect: {
				crossFade: true,
			},

			keyboard: {
				enabled: true,
			},

			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},

			pagination: {
				el: container.querySelector('.swiper-pagination'),
				type: 'fraction',
			},
		});
	}

	if (debug) console.log(`%c Succes : PDF viewer ready `, 'background: limegreen; color:black');
}

export { viewer };
