const debug = false;

function accordion(container = document, selector = '.accordion') {
	const accordions = container.querySelectorAll(selector);

	for (const accordion of Object.values(accordions)) {
		const accordionBtn = accordion.querySelector('.accordion__button');

		if (accordion) {
			function toggleAccordion() {
				accordion.classList.toggle('open');
			}

			accordionBtn.addEventListener('click', toggleAccordion);
		}

		if (debug) console.log(`%c Succes : accordion ready `, 'background: limegreen; color:black');
	}
}

export { accordion };
