import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
const debug = false;

function mainMenu(container = document) {
	// get the menu element
	let mainMenu = container.querySelector('.main-menu');
	if (mainMenu) {
		gsap.set('.hover__body > *', {
			yPercent: 10,
			autoAlpha: 0,
		});

		gsap.set('.main-menu--background__item', {
			autoAlpha: 0,
		});

		gsap.utils.toArray('.hover').forEach((acc) => {
			let accordionWrapper = acc.querySelector('.hover__wrapper');
			let accordionBtn = acc.querySelector('.hover__button');
			let accordionBody = acc.querySelector('.hover__body');
			let accordionBodyContent = acc.querySelector('.hover__body > *');
			let accBackgroundColor = acc.dataset.backgroundColor;
			let accTextColor = acc.dataset.textColor;
			let tl = gsap.timeline({ paused: true });
			let ease = 'power2.out';
			let speed = 0.75;

			tl.to(accordionBtn, {
				color: accTextColor,
				duration: speed,
				ease: ease,
			});

			tl.to(
				accordionBody,
				{
					maxHeight: accordionBodyContent.scrollHeight + 'px',
					duration: speed,
					ease: ease,
				},
				`-=${speed}`
			);

			tl.to(
				accordionBodyContent,
				{
					yPercent: 0,
					autoAlpha: 1,
					duration: speed,
					ease: ease,
				},
				`-=${speed}`
			);

			// laisser à la fin pour ne pas bloquer l'animation de l'accordion
			if (acc.id && container.querySelector(`.background--${acc.id}`)) {
				tl.to(
					`.background--${acc.id}`,
					{
						autoAlpha: 1,
						duration: speed,
						ease: ease,
					},
					`-=${speed / 2}`
				);
			} else {
				tl.to(
					'.page',
					{
						backgroundColor: accBackgroundColor,
						duration: speed,
						ease: ease,
					},
					`-=${speed / 2}`
				);
			}

			accordionBtn.addEventListener('mouseenter', () => {
				acc.classList.add('open');
				tl.timeScale(1).play();
			});
			accordionWrapper.addEventListener('mouseleave', () => {
				// tl.timeScale(3).reverse();
				tl.timeScale(2).reverse();
				acc.classList.remove('open');
			});
		});

		if (debug) console.log(`%c Main-menu Ready `, 'background:limegreen; color:black');
	}
}

export { mainMenu };
