import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
const debug = false;

// https://medium.com/@AlexanderObregon/how-to-build-a-dynamic-search-feature-with-javascript-filters-464b97038be1

function searchScrollAnimation(container = document) {
	// declare inside ?
	gsap.registerPlugin(ScrollTrigger);

	ScrollTrigger.defaults({
		scroller: '.page',
		// markers: true,
	});

	// different starting point between home page and others
	let namespace = container.querySelector('#main').dataset.barbaNamespace;
	let start = namespace == 'home' ? '10%' : '60%';

	let wrapper = container.querySelector('.search__wrapper');
	if (wrapper) {
		// set button position in searchAnimation() - init before -- leave me here

		gsap.to('.search__wrapper .search__button', {
			scrollTrigger: {
				trigger: '.content',
				toggleActions: 'play none none reverse',
				start: `${start} top`,
				end: 'end end',
			},
			xPercent: 0,
		});
	}

	if (debug) console.log('%c Succes: Search Bar Ready ', 'background: limegreen; color:black');
}

function searchAnimation(container = document) {
	let search = container.querySelector('.search');
	if (search) {
		let wrapper = container.querySelector('.search__wrapper');
		if (wrapper) {
			let button = wrapper.querySelector('.search__button');
			let engine = wrapper.querySelector('.search__engine');
			let bar = wrapper.querySelector('.search__bar');
			let backdrop = wrapper.querySelector('.search__backdrop');
			let tl = gsap.timeline({ paused: true });
			let power = 'power4.out';
			let speed = 0.5;

			let mm = gsap.matchMedia();
			let breakPoint = 768;

			mm.add(
				{
					// set up any number of arbitrarily-named conditions. The function below will be called when ANY of them match.
					isDesktop: `(min-width: ${breakPoint}px)`,
					isMobile: `(max-width: ${breakPoint - 1}px)`,
					reduceMotion: '(prefers-reduced-motion: reduce)',
				},
				(context) => {
					// context.conditions has a boolean property for each condition defined above indicating if it's matched or not.
					let { isDesktop, isMobile, reduceMotion } = context.conditions;

					// set button position
					gsap.set('.search .search__button', {
						xPercent: isDesktop ? -125 : 125,
					});

					gsap.set('.search .search__engine', {
						autoAlpha: 0,
					});

					gsap.set('.search .search__bar', {
						width: isDesktop ? '10%' : '100%',
						autoAlpha: 0,
						xPercent: isDesktop ? -10 : 0,
					});

					gsap.set('.search .search__backdrop', {
						autoAlpha: 0,
						xPercent: -100,
					});

					tl.to(engine, {
						autoAlpha: 1,
						duration: speed,
						ease: power,
					});

					tl.to(
						bar,
						{
							xPercent: 0,
							autoAlpha: 1,
							width: '100%',
							duration: speed,
							ease: power,
						},
						`=-0.1`
					);

					tl.set(
						backdrop,
						{
							xPercent: 0,
						},
						`=-${speed}`
					);

					tl.to(
						backdrop,
						{
							autoAlpha: 1,
							duration: speed,
							ease: 'none',
						},
						`=-${speed} + 0.1`
					);
				}
			);

			// open/close
			button.addEventListener('click', function () {
				if (wrapper.matches('.open')) {
					// empty search first
					wrapper.querySelector('.search__input').value = '';
					let listItems = wrapper.querySelectorAll('.search__item');
					listItems.forEach((listItem) => (listItem.style.display = 'none'));
					// animation
					tl.timeScale(3).reverse();
					wrapper.classList.remove('open');
				} else {
					wrapper.classList.add('open');
					tl.timeScale(1).play();
				}
			});
		}

		// set search visible after -- avoid flash on load
		gsap.set(search, {
			visibility: 'visible',
		});

		if (debug) console.log('%c Succes: Init Search Bar ', 'background: limegreen; color:black');
	}
}

function searchActions(box, event) {
	let listItems = box.querySelectorAll('.search__item');
	let terms = event.target.value.toLowerCase();

	if (terms.length > 0) {
		listItems.forEach(function (listItem) {
			let itemText = listItem.textContent.toLowerCase();
			let itemData = listItem.dataset.course;

			if (itemText.includes(terms)) {
				// fint text
				listItem.style.display = 'block';
			} else if (itemData.includes(terms)) {
				// find course
				listItem.style.display = 'block';
			} else {
				// else, hide
				listItem.style.display = 'none';
			}
		});
	} else {
		// hide all if there is no search terms
		listItems.forEach(function (listItem) {
			listItem.style.display = 'none';
		});
	}
}

function search(container = document) {
	// search function
	let wrapper = container.querySelector('.search__wrapper');
	if (wrapper) {
		let input = wrapper.querySelector('.search__input');

		input.addEventListener('input', function (event) {
			searchActions(wrapper, event);
		});
	}
}

export { searchScrollAnimation, searchAnimation, search };
