import Plyr from 'plyr';
const debug = false;

function slider(container = document, selector = '.swiper') {
	let thumb = container.querySelector('.thumb');

	if (thumb) {
		const t = new Swiper(thumb, {
			allowSlideNext: false,
			allowSlidePrev: false,
			watchSlidesProgress: true,
		});
	}

	let slider = container.querySelector(selector);

	if (slider) {
		const s = new Swiper(slider, {
			loop: true,
			speed: 0,
			autoHeight: true,
			allowTouchMove: false,

			lazy: true,
			lazyPreloadPrevNext: 1,

			thumbs: {
				swiper: thumb,
			},

			keyboard: {
				enabled: true,
			},

			navigation: {
				nextEl: '.swiper-button-next',
				prevEl: '.swiper-button-prev',
			},
		});
	}

	if (debug) console.log(`%c Succes : student slider ready `, 'background: limegreen; color:black');
}

function plyr() {
	const player = new Plyr('#player');
	if (debug) console.log('%c Succes: student Plyr ready ', 'background: limegreen; color:black');
}

export { slider, plyr };
