/* Main menu component script */
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);

function mainMenu(container = document) {
	let mainMenu = container.querySelector('.main-menu');
	if (mainMenu) {
		gsap.set('.hover__body > *', {
			yPercent: 10,
			autoAlpha: 0,
		});

		gsap.set('.main-menu--background__item', {
			autoAlpha: 0,
		});

		gsap.utils.toArray('.hover').forEach((acc) => {
			let accordionWrapper = acc.querySelector('.hover__wrapper');
			let accordionBtn = acc.querySelector('.hover__button');
			let accordionBody = acc.querySelector('.hover__body');
			let accordionBodyContent = acc.querySelector('.hover__body > *');
			let accBackgroundColor = acc.dataset.backgroundColor;
			let accTextColor = acc.dataset.textColor;
			let tl = gsap.timeline({ paused: true });
			let ease = 'power2.out';
			let speed = 0.75;

			tl.to(accordionBtn, {
				color: accTextColor,
				duration: speed,
				ease: ease,
			});

			tl.to(
				accordionBody,
				{
					maxHeight: accordionBodyContent.scrollHeight + 'px',
					duration: speed,
					ease: ease,
				},
				`-=${speed}`
			);

			tl.to(
				accordionBodyContent,
				{
					yPercent: 0,
					autoAlpha: 1,
					duration: speed,
					ease: ease,
				},
				`-=${speed}`
			);

			// laisser à la fin pour ne pas bloquer l'animation de l'accordion
			if (acc.id && document.querySelector(`.background--${acc.id}`)) {
				tl.to(
					`.background--${acc.id}`,
					{
						autoAlpha: 1,
						duration: speed,
						ease: ease,
					},
					`-=${speed / 2}`
				);
			} else {
				tl.to(
					'.js-menu-background',
					{
						backgroundColor: accBackgroundColor,
						duration: speed,
						ease: ease,
					},
					`-=${speed / 2}`
				);
			}

			accordionBtn.onmouseenter = () => {
				acc.classList.add('open');
				tl.timeScale(1).play();
			};

			accordionWrapper.onmouseleave = () => {
				tl.timeScale(2).reverse();
				acc.classList.remove('open');
			};

			accordionBtn.onclick = () => {
				tl.timeScale(2).reverse();
				acc.classList.remove('open');
			};
		});
	}
}

export { mainMenu };
