import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

function scrollToAcc(container = document) {
	gsap.registerPlugin(ScrollToPlugin);

	let headerHeight = document.querySelector('.header').offsetHeight;

	container.querySelectorAll('.info__item.accordion').forEach((btn, index) => {
		btn.addEventListener('click', () => {
			gsap.to('.page', {
				duration: 1,
				scrollTo: {
					y: `#${btn.id}`,
					offsetY: `${headerHeight}`,
					autoKill: true,
				},
			});
		});
	});
}

export { scrollToAcc };
